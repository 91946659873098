import ApplicationController from '@controllers/application_controller.js';
import { HeaderPillsHelper } from '@js/helpers/headers_helper.js';
import { CertificationRulesHelper } from './helpers/certification_rules_helper';

export default class extends ApplicationController {
    static values = {
        namePrefix: { type: String, default: '' }
    }

    initialize() {
        this.oppositeMode = {
            'include': 'exclude',
            'exclude': 'include'
        };

        this.helper = new CertificationRulesHelper(this.element, this.namePrefixValue);
        this.headerHelper = new HeaderPillsHelper($(this.element).find('.years-headers'))
        this.createEvents();
        this.switchChoiceDOMs();
    }

    switchChoiceDOMs() {
        let helper = this.helper;
        var events = [];

        $(this.element).find('.choice-btn')
                       .each((_, element) => {

            const mode = $(element).data('mode');
            let condition = $(element).data('condition');

            if (condition) {
                if (condition.type == 'range') {
                    // Switch to range and update range values
                    this.switchToSlider($(element));
                    let $targetDOM = this.targetDOM(mode);

                    if (condition['values'].length === 1) {
                        // User choose upper than a value so add artificial bound at
                        // the end
                        condition['values'].push(String(helper.nextYear))
                    }

                    // Update start for slider
                    helper.updateRangeValues(condition.values, mode);
                    $targetDOM.get(0)
                              .noUiSlider
                              .updateOptions({ start: condition.values })


                    events.push([
                        $targetDOM,
                        $.Event('slider.change'),
                        {
                            values: condition.values,
                            mode: mode,
                            handle: 0,
                            eventType: 'change'
                        }
                    ]);
                } else {
                    let $targetDOM = this.targetDOM(mode);
                    $targetDOM.val(condition.values);

                    condition.values.forEach((value) => {
                        events.push([
                            $targetDOM,
                            $.Event('select2:select', {
                                params: {
                                    data: {
                                        text: value
                                    }
                                }
                            })
                        ]);
                    })
                }
            } else {
                this.switchToSlider($(element));
            }
        });

        // Trigger all events
        events.forEach((e) => {
            e[0].trigger(e[1], e[2]);
        })
    }

    changeChoice(event) {
        let $choiceBtn = $(event.currentTarget)

        // If btn is disabled then do nothing
        if ( $choiceBtn.attr('disabled')) { return; }

        const choice = $choiceBtn.data('choice');
        switch(choice) {
            case 'select':
                this.switchToSlider($choiceBtn);
                break;
            case 'range':
                this.switchToSelect($choiceBtn);
                break;
        }
    }

    reset(event) {
        const mode = event.params.mode
        let $targetDOM = this.targetDOM(mode);

        if ($targetDOM.hasClass('noUi-target')) {
            // If no values selected on slider, return
            if ( $targetDOM.attr('disabled') || this.helper.rangeValues[mode].length == 0 ) {
                return;
            }

            this.helper.resetSlider($targetDOM.get(0), mode)
        } else {
            if ( $targetDOM.attr('disabled') || $targetDOM.val().length == 0 ) {
                return;
            }

            // Reset select
            $targetDOM.val(null).trigger('change');
            this.helper.selectedValues = new Set();
        }

        // Unlock opposite DOM
        this.unlockOpposite(mode);

        // Remove headers
        const circleColor = mode == 'include' ? '.color-green'
                                              : '.color-rouge';
        this.headerHelper.headerPillWithClass(circleColor)
                         .remove()
    }

    switchToSlider($choiceBtn) {
        // Switch from select to slider to choose years
        let sliderDom = $choiceBtn.prevAll('.year-select-field').get(0);
        const mode = $choiceBtn.data('mode');

        // Create slider in the right DOM
        this.helper.createSlider(sliderDom, mode);

        // Change switch button
        this.changeButton($choiceBtn, 'select');
    }

    switchToSelect($choiceBtn) {
        // Switch from slider to select for choosing years
        let selectDom = $choiceBtn.prevAll('.year-select-field').get(0);

        // Destroy slider
        selectDom.noUiSlider.destroy();
        $(selectDom).removeClass('mx-3');

        // Get select template which is just after btn
        let selectTemplate = $choiceBtn.next('template')
                                       .clone()
                                       .prop('content');

        // Append to the selectDom
        $(selectDom).append(selectTemplate);

        // Change switch Button
        this.changeButton($choiceBtn, 'range');

        // Disable options if opposite choice is slider
        if ($choiceBtn.data('mode') == 'exclude') {
            this.updateSelectOptions(this.targetDOM('exclude'),
                                     this.helper.rangeValues['include']);
        }
    }

    changeButton($choiceBtn, choice) {
        choice == 'select' ? $choiceBtn.data('choice', 'range')
                           : $choiceBtn.data('choice', 'select');
        $choiceBtn.find('.fas')
                  .toggleClass('fa-slider fa-hand-pointer');
    }

    createEvents() {
        const $element = $(this.element)
        const helper = this.helper;
        const headerHelper = this.headerHelper;
        const _this = this;

        $element.find('.year-select-field').on('select2:select select2:unselect', (e) => {
            let $selectDOM = $(e.target);
            let mode = $selectDOM.data('mode');

            let selectedValues = $selectDOM.val();

            // Update selected values in store
            helper.updateSelectedValues(selectedValues)

            // Update header
            let action = e.type == 'select2:select' ? 'add'
                                                    : 'remove'
            var circleColor = mode == 'include' ? 'color-green'
                                                : 'color-rouge';

            _this.headerHelper.updateHeader(e.params.data.text,
                                            action,
                                            circleColor)

            // Enable / Disable switch buttons and opposites 'select' or 'slider'
            // based on mode and values
            if ( selectedValues.length > 0 ) {
                helper.disableSwitchButton(mode,
                                           'selectedValues')
                if ( mode == 'include' ) {
                    _this.lockExclude();
                } else {
                    _this.lockInclude();
                }
            } else {
                // No values selected => Enable switch buttons
                _this.unlockOpposite(mode);

                if ( mode == 'exclude' ) {
                    // In those case we can enable switch button
                    helper.enableSwitchButton('exclude');
                }
            }
        });

        $element.on('slider.change', (e, data) => {
            const mode = data.mode

            // Lock switch button
            helper.disableSwitchButton(mode, 'selectedRange', true)

            // Change Header
            const circleColor = mode == 'include' ? 'color-green'
                                                  : 'color-rouge';

            const text = helper.getText(data.values);
            headerHelper.updateRangeHeader(text, circleColor);

            // Update Range values
            helper.updateRangeValues(data.values, mode);

            // Replace text if value is at upper bound
            helper.replaceText(e.target, data.values[1]);

            // Update opposite
            _this.updateOpposite(mode, data.values);

            if ( data.eventType == 'start' && mode == 'include' ) {
                // Update Include Margins if user start to slide on include
                this.updateIncludeMargin(data.values, data.handle);
            } else if ( data.eventType == 'start') {
                // Update Exclude Limits if user start to slide on exclude
                this.updateExcludeLimits(data.values, data.handle);
            }
        });
    }

    updateOpposite(mode, values){
        const oppositeMode = this.oppositeMode[mode];
        let $oppositeTargetDOM = this.targetDOM(oppositeMode);

        switch(mode) {
            case 'include':
                $oppositeTargetDOM.hasClass('noUi-target') ? this.updateExcludeBounds($oppositeTargetDOM, values)
                                                           : this.updateSelectOptions($oppositeTargetDOM, values);
                break
            case 'exclude':
                this.lockInclude();
                break
        }
    }

    updateIncludeMargin(values, handle) {
        const $includeDOM = this.targetDOM('include')

        // If DOM is not slider, then return
        if (!$includeDOM.hasClass('noUi-target')) { return; }

        // Build range values of exclude slider
        let helper = this.helper
        let rangeValues = [
            helper.rangeValues['exclude'][0] || Math.min(...helper.selectedValues),
            helper.rangeValues['exclude'][1] || Math.max(...helper.selectedValues),
        ]

        let margin = 4;
        if (isFinite(rangeValues[0])) {
            // Update margin based on which handle is moved
            margin = handle == 0 ? values[1] - rangeValues[0] + 1
                                 : rangeValues[1] - values[0] + 1;
        }

        $includeDOM.get(0)
                   .noUiSlider
                   .updateOptions({ margin: margin });
    }

    updateExcludeLimits(values, handle) {
        let rangeValues = this.helper.rangeValues['include'];
        const $excludeDOM = this.targetDOM('exclude');

        // Return if no values was set by user on include DOM
        if ( rangeValues.length == 0 ) { return; }

        // Else update limit of exclude slider
        values = values.map( x => parseInt(x, 10))

        let limit = handle == 0 ? values[1] - rangeValues[0] - 1
                                : rangeValues[1] - values[0] - 1;

        $excludeDOM.get(0)
                   .noUiSlider
                   .updateOptions({ limit: limit })
    }

    updateExcludeBounds($sliderDom, values) {
        // Return if no values was set by user on exclude DOM
        if ( this.helper.rangeValues['exclude'].length > 0 ) { return; }

        // Else update bounds that user could choose
        values = values.map( x => parseInt(x, 10))

        // New bounds will be [ include_min + 1, include_max -1 ]
        $sliderDom.get(0)
                  .noUiSlider
                  .updateOptions({
                    start: [ values[0] + 1, values[1] - 1 ],
                    margin: 2
                  })
    }

    updateSelectOptions($selectDom, values) {
        if ($selectDom.attr('disabled') || values.length == 0) { return; }


        // Close select2 dropdown
        if ($selectDom.hasClass('select2-hidden-accessible')) { $selectDom.select2('close') };

        // Disable all options outside of slider bound values
        values = values.map(value => parseInt(value, 10))

        // Build range values [v[0] + 1, v[1] - 1]
        values = Array(values[1] - values[0] - 1).fill()
                                                 .map((_, i) => values[0] + i + 1)
                                                 .map(String)

        // Disable options
        $selectDom.find('option')
                  .each(function(_, option) {
                      if (values.indexOf($(option).val()) === -1) {
                          $(option).attr('disabled', 'disabled')
                      } else {
                         $(option).removeAttr('disabled');
                      }
                  });

        $selectDom.trigger('change.select2');
    }

    unlockOpposite(mode) {
        if ( mode == 'include' ) {
            this.unlockExclude();
        } else {
            this.unlockInclude();
        }

        if ( mode == 'exclude' || this.helper.rangeValues['include'].length == 0 ) {
            this.helper.enableSwitchButton(mode)
        }
    }

    lockInclude() {
        let $includeSelect = this.includeSelect();
        if ($includeSelect.length > 0) {
            // Switch to slider if include are on select mode
            this.switchToSlider(this.helper
                                    .switchButton('include'));
        }

        // Add popover to disable button
        this.helper.disableSwitchButton('include',
                                        'includeLock')
    }

    unlockInclude() {
        if (this.helper.rangeValues['include'].length == 0 ) {
            // Enable switch button
            this.helper.enableSwitchButton('include');
        } else {
            // Otherwise (user select include range) we can't switch to select. We just reset limits
            // of slider
            this.helper.resetLimits(this.targetDOM('include').get(0))
            this.helper.disableSwitchButton('include',
                                            'selectedRange')
        }
    }

    targetDOM(mode) {
        return $(this.element).find(`select[data-mode='${mode}'],
                                     .noUi-target[data-mode='${mode}']`)
    }

    includeSelect() {
        return $(this.element).find('select[data-mode="include"]');
    }

    lockExclude() {
        let $excludeDOM = this.targetDOM('exclude');

        if ($excludeDOM.hasClass('noUi-target')) {
            // Opposite Dom is a slider
            $excludeDOM.get(0)
                       .noUiSlider
                       .disable();
        } else {
            // Opposite Dom is a select
            $excludeDOM.attr('disabled', 'disabled');
        }

        this.helper.disableSwitchButton('exclude',
                                        'excludeLock');
    }

    unlockExclude() {
        let $excludeDOM = this.targetDOM('exclude');

        if( $excludeDOM.hasClass('noUi-target')) {
            $excludeDOM.get(0)
                       .noUiSlider
                       .enable();

            const rangeValues = this.helper.rangeValues['exclude']

            if (rangeValues.length) {
                this.helper.resetLimits($excludeDOM.get(0))
            } else {
                this.helper.enableSwitchButton('exclude')
            }

        } else {
            $excludeDOM.removeAttr('disabled');
            this.helper.enableSwitchButton('exclude');
        }
    }
}