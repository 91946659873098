import ApplicationController from '@controllers/application_controller.js';

export default class extends ApplicationController {
    static targets = [ 'selectCertification' ]

    initialize() {
        this.selectedCertifications = new Set()
    }

    connect() {
        super.connect();
        this.selectCertificationTargets.forEach( (target) => {
            this.selectedCertifications = this.selectedCertifications
                                              .union( new Set($(target).val()) );
        })

        this.disableSelectedOptions(this.element);
    }

    removeRow(event) {
        let rowID = $(event.currentTarget).data('remove-target');

        this.stimulate("nestedField#remove", event.currentTarget)
            .then(() => {
                let selectedCertifications = $('#' + rowID).find('.select-certifications')
                                                           .val()

                // Remove from set of selected certifications
                this.selectedCertifications = this.selectedCertifications
                                                  .difference( new Set(selectedCertifications) )

                // Enable options
                selectedCertifications.forEach((val) => {
                    this.enableOption(val);
                });
            })
    }

    selectCertificationTargetConnected(element) {
        $(element).on('select2:select select2:unselect', (e) => {
            let value = e.params.data.id
            if (e.type == 'select2:select') {
                this.selectedCertifications.add(value);
                this.disableOption(value, element);
            } else {
                this.selectedCertifications.delete(value, element);
                this.enableOption(value);
            }
        })

        // Disable already selected options
        this.disableSelectedOptions(element);
    }

    disableSelectedOptions(element) {
        this.selectedCertifications.forEach((val) => {
            $(element).find(`option[value='${val}']:not(:selected)`)
                      .attr('disabled', true)
        });
    }

    enableOption(value) {
        $(this.selectCertificationTargets).find(`option[value='${value}']`)
                                          .attr('disabled', false)
    }

    disableOption(value, target) {
        $(this.selectCertificationTargets).not(target)
                                          .find(`option[value='${value}']`)
                                          .attr('disabled', true)
    }
}