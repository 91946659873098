import { Popover } from 'bootstrap';
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';

export class CertificationRulesHelper {
    constructor(connectedDom, namePrefix) {
        this.$connectedDom = $(connectedDom);
        this.namePrefix = namePrefix;

        // Store selected values and range values for include and exclude DOM
        this.selectedValues = new Set();
        this.rangeValues = { include: [],
                             exclude: [] }

        // Store current and next year
        this.currentYear = new Date().getFullYear()
        this.nextYear    = this.currentYear + 1

        // Build Popovers
        this.popovers = {
            include: new Popover(this.$connectedDom.find('.btn[data-mode="include"]')),
            exclude: new Popover(this.$connectedDom.find('.btn[data-mode="exclude"]'))
        }

        for ( const [_, popover] of Object.entries(this.popovers) ) {
            popover.disable()
        }

        this.disabledReasons = {
            selectedRange: "Désélectionnez votre plage d'années pour pouvoir repasser en mode sélection",
            selectedValues: "Désélectionnez les années pour pouvoir passer en mode plage",
            includeLock: "Il est impossible de passer en mode sélection avec une année exclue",
            excludeLock: "Il est impossible d'exclure une année avec des années sélectionnées"
        }
    }

    updateSelectedValues(values) {
        // Update set of values on select
        this.selectedValues = new Set(values)
    }

    updateRangeValues(values, mode) {
        // Update values attribute
        this.rangeValues[mode] = values.map(num => parseInt(num, 10))

        // Update hidden input field
        let name = mode === 'include' ? this.namePrefix + '[inclusions][]'
                                      : this.namePrefix + '[exclusions][]';

        $(`input[name='${name}']`).val(values.join(';'))
    }

    createSlider(sliderDom, mode) {
        if (sliderDom.noUiSlider) { return; }

        // Empty dom and create slider
        $(sliderDom).empty()
        $(sliderDom).addClass('mx-3')

        let includeRange = this.rangeValues['include'];
        let start = includeRange.length > 0 ? [ includeRange[0] + 1, includeRange[1] - 1 ]
                                            : [ 1800, this.currentYear + 1 ]

        let padding = mode === 'exclude' ? [1, 1] : [0 , 0]

        noUiSlider.cssClasses.connect = mode == 'include' ? 'connect connect-green'
                                                          : 'connect connect-red'

        noUiSlider.create(sliderDom, {
            start: start,
            behaviour: 'smooth-steps',
            connect: true,
            step: 1,
            margin: 4,
            padding: padding,
            range: {
                min: 1800,
                max: this.currentYear + 1
            },
            ariaFormat: wNumb({decimals: 0}),
            format: {
                to: function(value) { return wNumb({ decimals: 0 }).to(value) },
                from: function(value) { return wNumb({ decimals: 0 }).from(value) }
            }
        })

        this.bubbleSliderEvents(sliderDom, mode);

        // Replace upper handle text for inclusion
        this.replaceText(sliderDom, sliderDom.noUiSlider.get()[1])

        // Create hidden field to keep millesimes ranges
        this.createHiddenRange(sliderDom, mode)
    }

    bubbleSliderEvents(slider, mode) {

        for (const event of ['slide', 'change', 'start']) {
            slider.noUiSlider.on(`${event}`, function(values, handle) {
                $(slider).trigger('slider.change', {
                    values: values,
                    mode: mode,
                    handle: handle,
                    eventType: event
                });
            })
        }
    }

    createHiddenRange(dom, mode) {
        let name = mode === 'include' ? this.namePrefix + '[inclusions][]'
                                      : this.namePrefix + '[exclusions][]'
        $('<input>', {
            type: 'hidden',
            name: name
        }).appendTo(dom)
    }

    replaceText(slider, upperValue) {
        if (upperValue == this.nextYear) {
            $(slider).find('.noUi-handle-upper').attr('aria-valuetext',
                                                       String(this.currentYear) + '+')
        }
    }

    resetSlider(slider, mode) {
        let start = mode === 'include' ? [1800, this.currentYear + 1]
                                       : this.getExcludeRange();

        slider.noUiSlider.updateOptions({
            start: start,
            limit: null,
            margin: 4
        })

        this.rangeValues[mode] = []
        this.replaceText(slider, start[1])

        // Reset hidden range
        let name = mode === 'include' ? this.namePrefix + '[inclusions][]'
                                      : this.namePrefix + '[exclusions][]'

        $(`input[name='${name}']`).removeAttr('value')
    }

    resetSelect(selectDom, mode) {
        selectDom.val(null).trigger('change')
        this.selectedValues = new Set()
    }

    resetLimits(slider) {
        slider.noUiSlider.updateOptions({
            limit: null,
            margin: 4
        })

        this.replaceText(slider, this.rangeValues['include'][1])
    }

    switchButton(mode) {
        return this.$connectedDom
                   .find(`.btn[data-mode='${mode}']`)
    }

    getExcludeRange() {
        let rangeValues = this.rangeValues['include']

        let range = rangeValues.length > 0 ? [rangeValues[0] + 1, rangeValues[1] - 1]
                                           : [1800, this.currentYear]

        return range
    }

    disableSwitchButton(mode, reason, checkEnabled = false) {
        this.switchButton(mode).attr('disabled', 'disabled')

        if (!checkEnabled || !this.popovers[mode]._isEnabled) {
            this.popovers[mode]._config.content = this.disabledReasons[reason]
        }

        this.popovers[mode].enable()
    }

    enableSwitchButton(mode) {
        this.switchButton(mode).removeAttr('disabled')
        this.popovers[mode].disable()
    }

    getText(values) {
        values = [...new Set(values) ];
        let yearP = this.nextYear;

        return values.join(' - ').replace(String(yearP),
                                          `${yearP - 1}+`);
    }
}